import { atom, useAtomValue, useSetAtom } from "jotai";

export const broadcastIdAtom = atom ( undefined );
export const isSessionEnded = atom ( false );
export const reactionsCount = atom ( null );
export const ownReactionsCount = atom ( {} );
export const totalReactions = atom ( 0 );
export const reactions = atom ( {} );

export const useSetReactions = () => {
	return useSetAtom ( reactions );
};

export const useGetReactions = () => {
	return useAtomValue ( reactions );
};

export const useSetBroadcastId = () => {
	return useSetAtom ( broadcastIdAtom );
};

export const useGetBroadcastId = () => {
	return useAtomValue ( broadcastIdAtom );
};

export const useSetSessionEnded = () => {
	return useSetAtom ( isSessionEnded );
};

export const useGetSessionEnded = () => {
	return useAtomValue ( isSessionEnded );
};

export const useSetReactionsCount = () => {
	return useSetAtom ( reactionsCount );
};

export const useSetOwnReactionsCount = () => {
	return useSetAtom ( ownReactionsCount );
};

export const useGetOwnReactionsCount = () => {
	return useAtomValue ( ownReactionsCount );
};

export const useSetTotalReactions = () => {
	return useSetAtom ( totalReactions );
};

export const useGetTotalReactions = () => {
	return useAtomValue ( totalReactions );
};
