import { atom, useAtomValue, useSetAtom }     from "jotai";
import { RESET, atomWithReset, useResetAtom } from "jotai/utils";

export const socketState = atomWithReset ();
export const pinnedComment = atomWithReset ( null ); // initial value to be set with hydration from "pinnedComment"
export const isModerator = atomWithReset (); // initial value to be set with hydration from "isCurrentUserModerator"
export const dynamicData = atomWithReset ( {} ); // initial value setDynamicData ( { liveViews: data?.liveViews } );
export const donationGoal = atomWithReset ();
export const hasUserDonated = atomWithReset ( false );
export const message = atom ( null );

export const useSetIVSConnectionStatus = () => {
	return useSetAtom ( socketState );
};

export const useGetIVSConnectionStatus = () => {
	return useAtomValue ( socketState );
};

export const useSetPinnedComment = () => {
	return useSetAtom ( pinnedComment );
};

export const useGetPinnedComment = () => {
	return useAtomValue ( pinnedComment );
};

export const useSetIsModerator = () => {
	return useSetAtom ( isModerator );
};

export const useGetIsModerator = () => {
	return useAtomValue ( isModerator );
};

export const useSetDynamicData = () => {
	return useSetAtom ( dynamicData );
};

export const useGetDynamicData = () => {
	return useAtomValue ( dynamicData );
};

export const useSetDonationGoal = () => {
	return useSetAtom ( donationGoal );
};

export const useGetDonationGoal = () => {
	return useAtomValue ( donationGoal );
};

export const useSetMessage = () => {
	return useSetAtom ( message );
};

export const useGetMessage = () => {
	return useAtomValue ( message );
};

export const useSetHasUserDonated = () => {
	return useSetAtom ( hasUserDonated );
};

export const useGetHasUserDonated = () => {
	return useAtomValue ( hasUserDonated );
};

export const useResetAtoms = () => {
	const socketStateReset = useResetAtom ( socketState );
	// const pinnedCommentReset = useResetAtom ( pinnedComment );
	// const isModeratorReset   = useResetAtom ( isModerator );
	const dynamicDataReset    = useResetAtom ( dynamicData );
	const donationGoalReset   = useResetAtom ( donationGoal );
	const hasUserDonatedReset = useResetAtom ( hasUserDonated );

	// Call all reset funcitons here
	return () => {
		socketStateReset ( RESET );
		// pinnedCommentReset ( RESET );
		// isModeratorReset ( RESET );
		dynamicDataReset ( RESET );
		donationGoalReset ( RESET );
		hasUserDonatedReset ( RESET );
	};
};
