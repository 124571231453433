import { useRouter } from "next/router";

import useMediaQuery   from "_common/hooks/useMediaQuery";
import { isPageRoute } from "_common/utils";
import useIVSChatRoom  from "_components/features/ivsChat";

const ConnectIVSChat = ( { broadcastData } ) => {
	const router = useRouter ();

	const isStreamPage = isPageRoute ( { router, page: "stream" } );
	const isStudioPage = isPageRoute ( { router, page: "studio" } );
	const isPopoutPage = isPageRoute ( { router, page: "popout" } );

	const isLive         = !broadcastData?.endTimeUTC || false;
	const connectIVSChat = isStudioPage || isPopoutPage || ( isStreamPage && isLive ) ? true : false;

	useMediaQuery ();
	useIVSChatRoom ( connectIVSChat );

	return null;
};

export default ConnectIVSChat;
