import { ChatRoom }          from "amazon-ivs-chat-messaging";
import { useRef, useEffect } from "react";
import { useCallback }       from "react";

import { useGetProfileData }                       from "_common/hooks/auth";
import { useGetBroadcastId, useSetTotalReactions } from "_common/hooks/stream";
import Toast                                       from "_common/toast";
import { generateIVSChatToken }                    from "_services/api/livestream";

import {
	useSetIVSConnectionStatus,
	useSetPinnedComment,
	useResetAtoms,
	useSetIsModerator,
	useSetMessage,
	useSetDynamicData,
	useSetDonationGoal,
	useSetHasUserDonated
} from "./states";

const useCreateIVSChatRoom = connect => {
	const setSocketStatus   = useSetIVSConnectionStatus ();
	const setPinnedComment  = useSetPinnedComment ();
	const setDonationGoal   = useSetDonationGoal ();
	const broadcastId       = useGetBroadcastId ();
	const userProfile       = useGetProfileData ();
	const setIsModerator    = useSetIsModerator ();
	const setDynamicData    = useSetDynamicData ();
	const setTotalReactions = useSetTotalReactions ();
	const setHasUserDonated = useSetHasUserDonated ();

	const setMessage  = useSetMessage ();
	const resetStates = useResetAtoms ();
	const roomRef     = useRef ();

	useEffect ( () => {
		if ( !broadcastId || !connect )
			return;

		initChatroom ();

		return () => {
			resetStates ();
			roomRef.current?.disconnect ();
			roomRef.current = null;
		};
	}, [ broadcastId, connect ] );

	const initChatroom = useCallback ( async () => {
		if ( roomRef?.current )
			return;

		try {
			const data = await generateIVSChatToken ( broadcastId );

			roomRef.current = new ChatRoom ( {
				regionOrUrl   : data?.region,
				logLevel      : "error",
				tokenProvider : async () => {
					return {
						token                 : data?.token?.token,
						sessionExpirationTime : new Date ( data?.token?.sessionExpirationTime ),
						tokenExpirationTime   : new Date ( data?.token?.tokenExpirationTime )
					};
				}
			} );

			/** Called when a chat message has been received. */
			roomRef.current.addListener ( "message", data => {
				readMessage ( data );
			} );

			roomRef.current.addListener ( "connecting", () => {
				setSocketStatus ( "connecting" );
			} );

			roomRef.current.addListener ( "connect", () => {
				setSocketStatus ( "connected" );
			} );

			roomRef.current.addListener ( "userDisconnect", () => {
				setSocketStatus ( "user_disconnected" );
			} );

			roomRef.current.addListener ( "disconnect", err => {
				setSocketStatus ( "disconnected" );

				if ( err !== "clientDisconnect" )
					roomRef.current.connect ();
			} );

			// Default logging is debug which outputs a lot of logs on console
			roomRef.current.logLevel = "error";

			roomRef.current.connect ();
		} catch ( err ) {
			Toast.error ( "Couldn't connect to chat server." );
		}

	}, [ broadcastId, roomRef ] );

	const readMessage = message => {
		setMessage ( message );

		const eventName = message?.attributes?.eventName;
		let commentText = message?.content;

		if ( commentText === "NO_CONTENT" ) {
			commentText = "";
		}

		// const metadata = message?.attributes?.metadata;

		let metadata = null;

		try {
			if ( message?.attributes?.metadata && message.attributes.metadata.trim () !== "" ) {
				metadata = JSON.parse ( message.attributes.metadata );
			}
		} catch ( error ) {
			// If JSON parsing fails
			return;
		}

		if ( eventName === "SEND_MESSAGE" ) {
			if ( metadata?.sportsFan?.id === userProfile?.id && metadata?.commentType === "STREAM_DONATION" )
				setHasUserDonated ( true );
		}

		if ( eventName === "PIN_COMMENT" && !metadata )
			setPinnedComment ( null );

		if ( !metadata )
			return;

		if ( eventName === "UPDATE_SESSION_DATA" ) {

			const reactions = metadata?.reactions || [];

			if ( reactions?.length > 0 ) {
				const totalRxns = reactions[0]?.totalReactions;

				setTotalReactions ( totalRxns ); // sets total reactions

				// This is for flying emotes
				// if ( reactions?.length ) {
				// reactions.forEach ( item => handleReactions ( item ) );
				// break;
				// }

			}

			if ( metadata?.donationGoal )
				setDonationGoal ( metadata?.donationGoal );

			// update SessionData
			setDynamicData ( prev => {
				return { ...prev, ...metadata };
			} );
		}

		const __data = metadata;

		if ( eventName === "PIN_COMMENT" && __data?.id )
			setPinnedComment ( __data );

		if ( eventName === "REMOVE_MODERATOR" ) {
			const __userIds = metadata.userIds;
			const isInArray = __userIds.includes ( userProfile?.id );

			setIsModerator ( isInArray ? false : true );
		}

		if ( eventName === "ADD_MODERATOR" ) {
			const __userIds = metadata.userIds;
			const isInArray = __userIds.includes ( userProfile?.id );

			setIsModerator ( isInArray ? true : false );
		}
	};

	return null;
};

export default useCreateIVSChatRoom;
