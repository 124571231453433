import XHR         from "_common/xhr";
import { configs } from "_config/index";

export const getTagsForComments = async ( { broadcastSessionId } ) => {
	if ( !broadcastSessionId )
		return;
	const result = await XHR.get ( {
		url: `${ configs.LIVESTREAM_URL }session-comment-tags/${ broadcastSessionId }`
	} );

	return result?.data?.tags;
};

export const generateIVSChatToken = async broadcastId => {
	if ( !broadcastId )
		return;
	const result = await XHR.get ( {
		url: `${ configs.LIVESTREAM_URL }session-ivs-chat-credentials/${ broadcastId }`
	} );

	return result?.data;
};

export const checkStreamHealth = async channelId => {

	const result = await XHR.get ( {
		url: `${ configs.BROADCAST_DNS }stream-health?channelId=${ channelId }`
	} );

	return result;
};
